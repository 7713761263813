import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import LayoutConfig from "../../Config/layoutConfig";
import Map from "../../components/Common/Map";

import locationSvg from '../../image/contact/location.svg';
import phoneSvg from '../../image/contact/phone.svg';
import emailSvg from '../../image/contact/email.svg';

const ItoursIRContactUS = () => {

  const Dictionary = useSelector(state => state.VisualData.Dictionary);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="section-vertical-padding bg-white contact-us">
      <div className="page-container">

        <div className="itours-ir-contact-grid">
          <div className="content">
            <h2 className="title"> {Dictionary.contactUs} </h2>
            <p>{Dictionary.contactP1}</p>

            <div>
              <img src={phoneSvg} alt="phone" className="icon" />
              پشتیبانی همه روزه از ساعت <span className="en-font"> 9 </span> الی <span className="en-font"> 23 </span> : <span dir="ltr" className="no-wrap en-font">+98 21 91008091</span>

            </div>

            <div className="contact-row">

              <img src={emailSvg} alt="email" className="icon" />
              <span>
                شما همچنین می توانید برای ارتباط سریع تر از طریق آدرس ایمیل
                <a href="mailto:online@itours.ir" target="_blank" rel="noreferrer" className="en-font content-link margin-start-half margin-end-half" >Online@itours.ir</a>
                با ما در ارتباط باشید
              </span>

            </div>

            <div className="contact-row">
              <img src={locationSvg} alt="address" className="icon" />
              <span>
                {LayoutConfig?.contacts?.address || "تهران ، خیابان بهشتی ، خ وزرا پلاک ۲۷ ، واحد ۲۲"}
              </span>
            </div>

          </div>

          <Map
            zoom={14}
            hotels={[{ latitude: 35.729618, longitude: 51.415335 }]}
          />

        </div>

      </div>
    </div>
  );

}

export default ItoursIRContactUS;