import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Link } from "react-router-dom";
import Form from "../Common/Form";
import {
  editMessage,
  SwitchAccountWindow,
  toggleAccountWindow,
  changeSafarmarketLayout
} from "../../Actions/VisualDataAction";
import {
  getPreReserveID,
  postReserve
} from "../../Actions/HotelAction";
import Validation from "../Common/Validation";
import Loading from "../Common/Loading";
import { Currency } from "../../Enum/Models";
import { Steps } from "antd";
import 'antd/dist/antd.css';
import SummaryHotel from "./SummaryHotel";
import { CountryCode, CountryPostalCode } from "../../Enum/Models";
import LayoutConfig from "../../Config/layoutConfig";
import "react-phone-number-input/style.css";
import StickySides from "../Common/StickySides";
import FetchProgress from "../Common/FetchProgress";
import HotelGetpassengerSkeleton from "../Common/HotelGetpassengerSkeleton";
import AcceptRemarksModal from "./AcceptRemarksModal";

class GetPassenger extends Form {
  //init
  state = {
    ...this.state,
    username: this.props.userData?.emailAddress,
    isSendRequest: false,
    phoneNumber: null,
    showAcceptRemarksModal: false,
    submitLoading: false

  };
  preReserveKey;
  FormDOM = [];
  init = () => {
    this.props.editMessage(false);
    let parameters = queryString.parse(this.props.location.search);
    this.preReserveKey = parameters.preReserveKey;
    let params = new URLSearchParams();
    params.append('preReserveKey', this.preReserveKey);
    this.props.getPreReserveID(params);
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.editMessage(false);
    this.init();
    //super.componentDidMount(this.FormDOM);
  }

  componentWillUnmount(){
    this.props.setSafarmarketLayout(false);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.userData !== this.props.userData) {
      if (this.props.userData !== null) {
        this.setState({
          username: this.props.userName,
        });
      }
      this.render();
    }
    if (this.props.respond === true && this.state.isSendRequest === true) {
      let url = `/payment/reserve?reserveId=${this.props.reserveId}`;
      const parameters = queryString.parse(this.props.location.search);
      if (parameters.smid) {
        url = `/payment/reserve?reserveId=${this.props.reserveId}&smid=${parameters.smid}`;
      }
      this.props.history.push(url);
    }
  }

  callPrereserve(){
    this.setState(
      {
        isSendRequest: true,
        submitLoading: true
      },
      () => {
        this.props.postReserve(this.state.DataInput, this.props.LAN === "IR" ? "fa-IR" : "en-US");
      }
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    super.validateForm(event, () => {
      this.props.editMessage(false);
      //exception code for adding phone number
      let inputData = JSON.stringify(this.state.DataInput);
      inputData = JSON.parse(inputData);
      let code = inputData.reserver.postalcode;
      if (code.substring(0, 2) === "00"){
        code = "+" + code.substring(2)
      }
      inputData.reserver.phoneNumber = code + '' + inputData.reserver.phoneNumber;
      inputData.reserver.username = inputData.reserver.username ? inputData.reserver.username : this.state.DataInput.reserver.email;
      
      const remarks = this.props.hotelInfo?.accommodation?.rooms?.[0]?.remarks;
      
      if(remarks){
        this.setState({
          DataInput: inputData,
          showAcceptRemarksModal: true
        });
      }else{       
        this.setState(
          {
            DataInput: inputData,
          },
          this.callPrereserve
        );
      }

    });
  }
  onChangeValidation(event, type, maxLength) {
    if (maxLength) {
      if (event.target.value.length > maxLength) {
        const prevVal = event.target.value.substring(0, maxLength);
        event.target.value = prevVal;
      }
    }
    let regex;
    switch (type) {
      case 'number':
        regex = /^[0-9۰-۹٠-٩]*$/;
        break;
      case 'enAlphabet':
        regex = /^[a-zA-Z ]*$/;
        break;
      case 'faAlphabet':
        regex = /^[\u0600-\u06FF ]*$/;
        break;
      case 'email':
        regex = /[^\u0600-\u06FF\s]/;
        break;
      case 'passport':        
        regex = /^[a-zA-Z0-9۰-۹٠-٩]*$/;
        break;
      default:
        regex = /^[a-zA-Z ]*$/;
    }

    if (!regex.test(event.target.value)) {
      const prevVal = event.target.value.substring(0, event.target.value.length - 1);
      if (regex.test(prevVal)) {
        event.target.value = prevVal;
      } else {
        event.target.value = '';
      }
    }else{
      if(type === "passport"){
        event.target.value = event.target.value.toUpperCase();
      }
    }
  }

  ifEnglish = string => {
    const regex = /^[a-zA-Z ]*$/;
    if (!regex.test(string)){
      return "";
    }
    return string;
  }

  render() {
    const { Dictionary, LAN } = this.props;
    let night = 0;
    let totalPrice = 0;
    if (this.props.currencyCode !== null) {
      let currency = this.props.currencyCode.toString();
      this.currecySign = Currency[currency];
    } else {
      this.currecySign = '???';
    }
    let RoomRender = [];
    const { hotelInfo, userData } = this.props;
    const { Step } = Steps;

    let userInfoStatus = false;
    if (userData && userData.id){
       userInfoStatus = true;
    }
    
    const updatedUserData = userInfoStatus ? {
      ...userData,
      firstName:this.ifEnglish(userData.firstName),
      lastName:this.ifEnglish(userData.lastName)
    } : undefined;

    
    if (hotelInfo) {
      night = hotelInfo.date.duration;
      if (hotelInfo.accommodation) {
        hotelInfo.accommodation.rooms.forEach((item) => {
          totalPrice += parseFloat(item.salePrice);
          let passenger = { adults: [], children: [] };
          for (let itr = 1; itr <= item.adults; itr++) {
            passenger.adults.push(itr);
          }
          for (let itr = 1; itr <= item.children; itr++) {
            passenger.children.push(itr);
          }
          RoomRender.push(passenger);
        });
      }
    }
    let passengerCounter = -1;
    let userPhoneNumber = undefined;

    let defaultNationality = this.props.IPLocation || LAN === 'IR' ? 'IR' : LAN === "TR" ? "TR" : 'NO';
    if (process.env.REACT_APP_THEME=== "AYANAIRCOM"){
      defaultNationality = "IQ";
    }
    
    const isStyle3 = LayoutConfig.themeClassName === 'style-3';

    const hasRemark = hotelInfo?.accommodation?.rooms?.find(r => r.remarks);

    return (
      <div className="get-passenger-form-holder">

        {this.state.showAcceptRemarksModal && (
          <AcceptRemarksModal 
            onAccept={ () => {this.callPrereserve() }}
            remarks={hotelInfo?.accommodation?.rooms?.[0]?.remarks}
            submitLoading={this.state.submitLoading}
          />
        )}

        {!!isStyle3 && <FetchProgress compeleted={!!hotelInfo} />}

        {hotelInfo ? (
          <form id={'reserveForm'} onSubmit={this.handleSubmit.bind(this)} ref={(node) => (this.FormDOM = node)} noValidate>
            <div className="page-padding getpassenger-page">
              <div className="page-container hotel-get-passenger">
                <Steps current={1}>
                  <Step title={Dictionary.chooseFlightsOrHotels} />
                  <Step title={Dictionary.enterThePassengersInformation} />
                  <Step title={Dictionary.paymentTypeOptions} />
                </Steps>
                {/* <h2 className="page-title">Hotellreservasjon</h2> */}

                {/* {userInfoStatus &&
                  (LayoutConfig.themeClassName === "padide-com-theme" || (
                    <div className="card margin-bottom card-padding bg-white box-border clearfix blue-border-right">
                      <span className="inline-block align-btns">
                        {" "}
                        {
                          Dictionary.LoginToYourAccountOrGoThroughReservationsWithoutRegistering
                        }
                        .
                      </span>
                      <div className="inline-block pull-end">
                        <button
                          type={"button"}
                          onClick={() => {
                            goToAnchor("reserveForm");
                          }}
                          className="button red-btn margin-right mobile-full-width-btn"
                        >
                          {Dictionary.ReservationWithoutRegistration}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.props.switchLogin(false);
                            this.props.check();
                          }}
                          className="button color-1-btn margin-right mobile-full-width-btn"
                        >
                          {Dictionary.login}
                        </button>
                      </div>
                    </div>
                  ))} */}
                <div className="getPassengers">
                    {hotelInfo.accommodation !== undefined && <StickySides
                      main={<>
                        <h5 className="normal-title">
                          {/* <span className="counter-circle"></span> */}
                          {Dictionary.reserverContactInformation}({Dictionary.BookingInformationsWillbeSentToThisNumberOrEmail})
                        </h5>
                        <div className={`margin-bottom bg-white responsive-bg-transparent box-border card-padding ${isStyle3?"":"blue-border-right"}`}>
                          <div className="inline-radio">
                            <div className="radio-item">
                              <input className="custom-radio" type="radio" name={'reserver.gender'} value={true} defaultChecked={true} />
                              <label className="radio-label">
                                <span className="radio-mark"></span>
                                {Dictionary.male}
                              </label>
                            </div>
                            <div className="radio-item">
                              <input className="custom-radio" type="radio" name={'reserver.gender'} value={false} />
                              <label className="radio-label">
                                <span className="radio-mark"></span>
                                {Dictionary.female}
                              </label>
                            </div>
                          </div>
                          <div className="float-row margin-bottom">
                            <div className="col-small-12 col-medium-6">
                              <div className="form-group relative required">
                                <label className="form-label">{Dictionary.firstNameEn}</label>
                                <input
                                  onChange={(e) => {
                                    this.onChangeValidation(e, 'enAlphabet');
                                  }}
                                  data-validator-type="alphabet"
                                  className="form-input placeholder-right en-input-text"
                                  data-validator={true}
                                  placeholder={Dictionary.firstName}
                                  name="reserver.firstName"
                                  type="text"
                                  defaultValue={updatedUserData?.firstName || ""}
                                />
                                <Validation model={this.state} feild="ValidatorList" name="reserver.firstName" />
                              </div>
                            </div>
                            <div className="col-small-12 col-medium-6">
                              <div className="form-group relative required">
                                <label className="form-label">{Dictionary.lastNameEn}</label>
                                <input
                                  onChange={(e) => {
                                    this.onChangeValidation(e, 'enAlphabet');
                                  }}
                                  data-validator-type="alphabet"
                                  className="form-input placeholder-right en-input-text"
                                  data-validator={true}
                                  placeholder={Dictionary.lastName}
                                  name="reserver.lastName"
                                  type="text"
                                  defaultValue={updatedUserData?.lastName || ""}
                                />
                                <Validation model={this.state} feild="ValidatorList" name="reserver.lastName" />
                              </div>
                            </div>
                            <div className="col-small-12 col-medium-6">
                              <div className="form-group relative required">
                                <label className="form-label">{Dictionary.email}</label>
                                <input
                                  onChange={(e) => {
                                    this.onChangeValidation(e, 'email');
                                  }}
                                  className="form-input placeholder-right en-input-text"
                                  data-validator={true}
                                  placeholder={Dictionary.enterEmail}
                                  defaultValue={userInfoStatus ? userData.emailAddress : ""}
                                  name="reserver.email"
                                  type="email"
                                />
                                <Validation model={this.state} feild="ValidatorList" name="reserver.email" />
                              </div>
                            </div>
                            <div className="col-small-12 col-medium-6">
                              <div className="form-group relative required">
                                <label className="form-label">{Dictionary.phoneNumber}</label>
                                <div className="select-input-pair-holder" dir="ltr">
                                  <select className="form-select" name={'reserver.postalcode'} dir="ltr">
                                    {CountryPostalCode.sort((a, b) => {
                                      let x = a.code.toLowerCase();
                                      let y = b.code.toLowerCase();
                                      if (x < y) {
                                        return -1;
                                      }
                                      if (x > y) {
                                        return 1;
                                      }
                                      return 0;
                                    }).map((item) => {
                                      let seleceted = false;
                                      if (userData && userData.phoneNumber) {
                                        let code = item.value;
                                        if (code.substring(0, 2) === '00') {
                                          code = '+' + code.substring(2);
                                        }
                                        if (userData.phoneNumber.includes(code)) {
                                          seleceted = true;
                                          let phone_Number = userData.phoneNumber.replace(code, '');
                                          userPhoneNumber = phone_Number;
                                        }
                                      } else {
                                        if (
                                          (item.value === "0098" && LAN === "IR") 
                                          || (item.value === "0090" && LAN === "TR") 
                                          || (item.value === "00971" && LAN === "AR" && (process.env.REACT_APP_THEME !=='AYANAIRCOM')) 
                                          || (item.code === "Norway (+47)" && LAN !== "AR" && LAN !== "IR" && process.env.REACT_APP_THEME !=='AYANAIRCOM')
                                          || (item.value === '00964' && process.env.REACT_APP_THEME ==='AYANAIRCOM')
                                        ) {
                                          seleceted = true;
                                        }
                                      }
                                      return (
                                        <option key={item.code} value={item.value} selected={seleceted}>
                                          {item.code}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <input
                                    onChange={(e) => {
                                      this.onChangeValidation(e, 'number');
                                    }}
                                    className="form-input placeholder-right en-input-text"
                                    type={'tel'}
                                    data-validator={LAN === 'IR'}
                                    defaultValue={userPhoneNumber ? userPhoneNumber : undefined}
                                    placeholder={'9XXXXXXXXX'}
                                    name={'reserver.phoneNumber'}
                                  />
                                </div>
                                <Validation model={this.state} feild="ValidatorList" name="reserver.phoneNumber" />
                              </div>
                            </div>
                          </div>

                          {userInfoStatus ? <input type="hidden" name="reserver.userID" value={userData.id} /> : null}
                          <input type="hidden" name="reserver.username" defaultValue={this.state.username} />
                        </div>
                        <div className={`box-border bg-white  responsive-bg-transparent get-passenger-form-holder margin-bottom ${isStyle3?"":"blue-border-right"}`}>
                          <div className="hr_padding">
                            <h5 className="title">{Dictionary.PassengersInformation}</h5>
                          </div>
                          <div className={RoomRender.length > 1 ? 'multiple-room' : ' '}>
                            {RoomRender.map((item, index) => {
                              return (
                                <div className="RoomItem card-padding" key={index}>
                                  <h5 className="normal-title">
                                    <span className="inline-block margin-end">
                                      {Dictionary.room} {index + 1}
                                    </span>
                                    <span className="inline-block en-font" dir="ltr">
                                      {' '}
                                      ({hotelInfo.accommodation.rooms[index].name}){' '}
                                    </span>
                                  </h5>
                                  {
                                    <div className="float-row">
                                      <div>
                                        <div className="col-small-12">{Dictionary.informationOfAdultTravelers}</div>
                                        {item.adults.map((item, indexIN) => {
                                          passengerCounter++;
                                          return (
                                            <div key={indexIN}>
                                              <div className="col-small-12">
                                                <div className="inline-radio">
                                                  <div className="radio-item">
                                                    <input
                                                      className="custom-radio"
                                                      type="radio"
                                                      name={`passengers[${passengerCounter}].gender`}
                                                      value={true}
                                                      defaultChecked={true}
                                                    />
                                                    <label className="radio-label">
                                                      <span className="radio-mark"></span>
                                                      {Dictionary.male}
                                                    </label>
                                                  </div>
                                                  <div className="radio-item">
                                                    <input
                                                      className="custom-radio"
                                                      type="radio"
                                                      name={`passengers[${passengerCounter}].gender`}
                                                      value={false}
                                                    />
                                                    <label className="radio-label">
                                                      <span className="radio-mark"></span>
                                                      {Dictionary.female}
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-xsmall-12 col-small-4">
                                                <div className="form-group relative required">
                                                  <label className="form-label">{Dictionary.firstNameEn}</label>
                                                  <input
                                                    onChange={(e) => {
                                                      this.onChangeValidation(e, 'enAlphabet');
                                                    }}
                                                    data-validator-type="alphabet"
                                                    className="form-input"
                                                    data-validator={true}
                                                    placeholder={Dictionary.firstName}
                                                    name={`passengers[${passengerCounter}].firstName`}
                                                    type="text"
                                                  />
                                                  <Validation model={this.state} feild="ValidatorList" name={`passengers[${passengerCounter}].firstName`} />
                                                </div>
                                              </div>
                                              <div className="col-xsmall-12 col-small-4">
                                                <div className="form-group relative required">
                                                  <label className="form-label">{Dictionary.lastNameEn}</label>
                                                  <input
                                                    onChange={(e) => {
                                                      this.onChangeValidation(e, 'enAlphabet');
                                                    }}
                                                    data-validator-type="alphabet"
                                                    className="form-input"
                                                    data-validator={true}
                                                    placeholder={Dictionary.lastName}
                                                    name={`passengers[${passengerCounter}].lastName`}
                                                    type="text"
                                                  />
                                                  <Validation model={this.state} feild="ValidatorList" name={`passengers[${passengerCounter}].lastName`} />
                                                </div>
                                              </div>
                                              <div className="col-xsmall-12 col-small-4">
                                                <div className="form-group relative required">
                                                  <label className="form-label">{Dictionary.nationality}</label>
                                                  <select
                                                    className="form-select"
                                                    name={`passengers[${passengerCounter}].nationalityCode`}
                                                    defaultValue={defaultNationality}
                                                    data-validator={true}>
                                                    {' '}
                                                    <option disabled selected value="false">
                                                      {' '}
                                                      --{' '}
                                                    </option>
                                                    {CountryCode.map((item) => (
                                                      <option value={item.value} key={item.value}>
                                                        {item.code}
                                                      </option>
                                                    ))}
                                                  </select>
                                                  <Validation
                                                    model={this.state}
                                                    feild="ValidatorList"
                                                    name={`passengers[${passengerCounter}].nationalityCode`}
                                                  />
                                                </div>
                                              </div>
                                              <input
                                                data-validator={false}
                                                value={index + 1}
                                                name={`passengers[${passengerCounter}].roomNumber`}
                                                type="hidden"
                                              />
                                              <input
                                                data-validator={false}
                                                value={'Adult'}
                                                name={`passengers[${passengerCounter}].ageCategory`}
                                                type="hidden"
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <div>
                                        <div className="col-small-12">
                                          {item.children.length > 0 && <span>{Dictionary.informationOfChildrenTravelers}</span>}
                                        </div>
                                        {item.children.map((item, indexIN) => {
                                          passengerCounter++;
                                          return (
                                            <>
                                              <div className="col-small-12">
                                                <div className="inline-radio">
                                                  <div className="radio-item">
                                                    <input
                                                      className="custom-radio"
                                                      type="radio"
                                                      name={`passengers[${passengerCounter}].gender`}
                                                      value={true}
                                                      defaultChecked={true}
                                                    />
                                                    <label className="radio-label">
                                                      <span className="radio-mark"></span>
                                                      {Dictionary.male}
                                                    </label>
                                                  </div>
                                                  <div className="radio-item">
                                                    <input
                                                      className="custom-radio"
                                                      type="radio"
                                                      name={`passengers[${passengerCounter}].gender`}
                                                      value={false}
                                                    />
                                                    <label className="radio-label">
                                                      <span className="radio-mark"></span>
                                                      {Dictionary.female}
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-small-12 col-medium-6 col-large-4">
                                                <div className="form-group relative required">
                                                  <label className="form-label">{Dictionary.firstNameEn}</label>
                                                  <input
                                                    data-validator-type="alphabet"
                                                    className="form-input"
                                                    data-validator={true}
                                                    placeholder={Dictionary.firstName}
                                                    name={`passengers[${passengerCounter}].firstName`}
                                                    type="text"
                                                  />
                                                  <Validation model={this.state} feild="ValidatorList" name={`passengers[${passengerCounter}].firstName`} />
                                                </div>
                                              </div>
                                              <div className="col-small-12 col-medium-6 col-large-4">
                                                <div className="form-group relative required">
                                                  <label className="form-label">{Dictionary.lastNameEn}</label>
                                                  <input
                                                    data-validator-type="alphabet"
                                                    className="form-input"
                                                    data-validator={true}
                                                    placeholder={Dictionary.lastName}
                                                    name={`passengers[${passengerCounter}].lastName`}
                                                    type="text"
                                                  />
                                                  <Validation model={this.state} feild="ValidatorList" name={`passengers[${passengerCounter}].lastName`} />
                                                </div>
                                              </div>
                                              <div className="col-small-12 col-medium-12 col-large-4">
                                                <div className="form-group relative required">
                                                  <label className="form-label">{Dictionary.nationality}</label>
                                                  <select
                                                    className="form-select"
                                                    name={`passengers[${passengerCounter}].nationalityCode`}
                                                    defaultValue={defaultNationality}
                                                    data-validator={true}>
                                                    {' '}
                                                    <option disabled selected value="false">
                                                      {' '}
                                                      --{' '}
                                                    </option>
                                                    {CountryCode.map((item) => (
                                                      <option value={item.value} key={item.value}>
                                                        {item.code}
                                                      </option>
                                                    ))}
                                                  </select>
                                                  <Validation
                                                    model={this.state}
                                                    feild="ValidatorList"
                                                    name={`passengers[${passengerCounter}].nationalityCode`}
                                                  />
                                                </div>
                                              </div>
                                              <input
                                                data-validator={false}
                                                value={index + 1}
                                                name={`passengers[${passengerCounter}].roomNumber`}
                                                type="hidden"
                                              />
                                              <input
                                                data-validator={false}
                                                value={'Child '}
                                                name={`passengers[${passengerCounter}].ageCategory`}
                                                type="hidden"
                                              />
                                            </>
                                          );
                                        })}
                                      </div>
                                      <input type="hidden" name="preReserveKey" value={this.preReserveKey} />
                                    </div>
                                  }
                                </div>
                              );
                            })}
                            {!hasRemark && <div className="border-top padding-h-20 padding-bottom">
                              <div className="checkbox-holder has-link">
                                <input className="custom-checkbox" type="checkbox" data-validator={true} name={`acceptRuls`} />
                                <label className="checkbox-label font-14">
                                  {LAN === 'IR' ? (
                                    <>
                                      <Link to={'/terms'} className="content-link">
                                        قوانین و مقررات آیتورز{' '}
                                      </Link>
                                      را خوانده ام و با آن موافق هستم.
                                    </>
                                  ) : (
                                    <>
                                      {Dictionary.iHaveReadAndAgreeToThe}
                                      <Link to={'/terms'} className="content-link" target="_blank">
                                        {Dictionary.termsAndConditions}
                                      </Link>
                                    </>
                                  )}
                                </label>
                                <Validation model={this.state} feild="ValidatorList" name={`acceptRuls`} />
                              </div>
                            </div>}
                          </div>
                        </div>

                        <div className={`card bg-white responsive-bg-transparent card-padding box-border ${isStyle3?"":"blue-border-right"}`}>
                          <div className="float-row">
                            <div className="col-small-12 col-large-9">
                              <div className="form-group">
                                <h5 className="normal-title">{Dictionary.description}</h5>
                                <p>
                                  {Dictionary.EnterYourRequestsHereAndWeWillShareThemWithTheHotel}.
                                  {Dictionary.SpecialRequestsAreNotGuaranteedButTheHotelWillDoEverythingToMeetYourNeeds}
                                </p>
                                <textarea className="form-textarea" name="specialRequest"></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>}
                      aside={<>
                        <SummaryHotel
                          submitLoading={this.state.submitLoading}
                          model={hotelInfo}
                          currencyCode={this.props.currencyCode}
                          showButton={true}
                          totalPrice={totalPrice}
                          night={night}
                          cancellationPolicy={hotelInfo.cancellationPolicy}
                        />
                        <div className="box-border top-color-border orange margin-top bg-white">
                          <div className="colored">{Dictionary.pricesToRiseSoon}!</div>
                          <div className="font-12"> {Dictionary.bookNowToGetTheBestRateForYourCheckinDate}. </div>
                        </div>
                      </>
                      }
                    />}
                </div>
              </div>
            </div>
          </form>
        ): isStyle3 ?(
          <HotelGetpassengerSkeleton />
        ):(
          <Loading fullPage description={Dictionary.GettingPassengerInformation + ' ...'} />
        )}
      </div>
    )
  }
}
const dispatchMapProp = (dispatch) => ({
  editMessage: (param) => {
    dispatch(editMessage(param));
  },
  getPreReserveID: (param) => {
    dispatch(getPreReserveID(param));
  },
  switchLogin: (mode) => {
    dispatch(SwitchAccountWindow(mode));
  },
  check: () => dispatch(toggleAccountWindow()),
  postReserve: (params, acceptLanguage) => dispatch(postReserve(params, acceptLanguage)),
  setSafarmarketLayout : data => dispatch (changeSafarmarketLayout(data))
});
const stateMapProp = (state) => ({
  respond: state.VisualData.Responded,
  hotelInfo: state.HotelSearch.selectedHotel,
  userData: state.UserData.userData,
  reserveId: state.HotelSearch.reserveId,
  currencyCode: state.UserData.Currency,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  IPLocation: state.VisualData.ipLocation
});
export default withRouter(connect(stateMapProp, dispatchMapProp)(GetPassenger));
