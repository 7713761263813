import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import LayoutConfig from "../../Config/layoutConfig";

import locationSvg from '../../image/namintravel/location-red.svg';
import phoneSvg from '../../image/namintravel/phone-red.svg';
import emailSvg from '../../image/namintravel/email-red.svg';

const NaminContactUS = () => {

  const Dictionary = useSelector(state => state.VisualData.Dictionary);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="section-vertical-padding contact-us">
      <div className="page-container">
        <br />
        <p className="margin-bottom">
        {Dictionary.contactP1}
        </p>

        <div className="namin-contact-grid">
          <div>
            <h5 className="normal-title"> Head Office: </h5>

            <div className="group">
              <img src={phoneSvg} alt="phone" className="icon margin-end-light" />
              <div className="border-left">
                <a href="tel:+4722112744" className="block contact-row content-link">
                  <span dir="ltr"> 0047 22112744 </span>
                </a>
                <a href="tel:+4799870871" className="block contact-row content-link">
                  <span dir="ltr"> 0047 99870871 </span>
                </a>
                <a href="tel:+4799870871" className="block contact-row content-link">
                  <span dir="ltr"> 0046 313608885 </span>
                </a>
              </div>

            </div>

          </div>
          <div>
            <h5 className="normal-title"> WhatsApp Support Only: </h5>

            <div className="group">
              <span className="margin-end-half itours-icon whatsapp icon-small2 margin-end-light" />
              <div className="border-left">
                <a href="https://wa.me/4796824768" className="block contact-row content-link">
                  <span dir="ltr"> 0047 96824768 </span>
                </a>
                <a href="https://wa.me/4793980869" className="block contact-row content-link">
                  <span dir="ltr"> 0047 93980869 </span>
                </a>
              </div>

            </div>

          </div>
          <div>
            <h5 className="normal-title"> {Dictionary.email}: </h5>
            <a
              href={`mailto:${LayoutConfig.contacts.email}`}
              dir="ltr"
              className="block en-font content-link"
            > <img src={emailSvg} alt="email" className="icon margin-end-light" /> {LayoutConfig.contacts.email}</a>

          </div>
          <div>
            <h5 className="normal-title"> {Dictionary.Address}: </h5>
            <span className="block en-font" >
              <img src={locationSvg} alt="location" className="icon margin-end-light" /> {LayoutConfig.contacts.address}
            </span>
          </div>
        </div>

      </div>
      <br/>
    </div>
  );

}

export default NaminContactUS;