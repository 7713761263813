import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {Skeleton, Steps} from 'antd';
import Form from "../../components/Common/Form";
import queryString from 'query-string';
import SafarmarketWelcome from "../../components/Common/SafarmarketWelcome";
import axios from "axios";
import Validation from "../../components/Common/Validation";
import { getPassengerFlight,setSafarmarketPixel } from "../../Actions/FlightAction";
import {Header, Flight as FlightURL, ServerAddress} from "../../Enum/Urls";
import {showError,editMessage,showAccountWindowIR} from "../../Actions/VisualDataAction"
import { CountryPostalCode } from "../../Enum/Models";
import Loading from "../../components/Common/Loading";
import Price from "../../components/Common/Price";
import formIconOrange from "../../image/form-icon.svg";
import formIconPink from "../../image/form-icon-pink.svg";
import FlighSummary from "../../components/Flight/FlighSummary";
import LayoutConfig from "../../Config/layoutConfig";
import StickySides from "../../components/Common/StickySides";
import AirlineWarning from "../../components/Flight/AirlineWarning";
import { FormIcon } from "../../components/Common/icons";
import FetchProgress from "../../components/Common/FetchProgress";
import { getTravellers } from "../../Actions/TravellerAction";
import PassengerItem from "../../components/Flight/PassengerItem";
// import CancelWarningModal from "../../components/Flight/CancelWarningModal";

let source = axios.CancelToken.source();

class FlightGetPassenger extends Form{

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      flightSelected:undefined,
      showPersian:false,
      passportExpMin:undefined,
      passengerInformation:[],
      calculatedTotalPriceWithExtraBaggage:[],
      reserveLoading:false,
      isFromSafarmarket:false,
      getPnrParams:undefined,
      detailsIsOpen:false,
      IAWarningModalVisibility:true,
      travellers:[],
      travellersLoading:false
    };

    source = axios.CancelToken.source();
  }  

  validateFlight=()=>{
      let parameters=queryString.parse(this.props.location.search);
      let url = `${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.ValidateFlight}?key=${parameters.key}`;     
      let metaId,metaSource;
      let cookies = decodeURIComponent(document.cookie).split(';');
      for (const item of cookies){
        if (item.includes("safarMarketId=")){
          metaId=item.split("=")[1];
          metaSource="safarmarket";
        }
      }
      if (parameters.safarmarketId && parameters.utm_medium && parameters.utm_source && parameters.utm_source === "safarmarket"){
        metaId=parameters.safarmarketId;
        metaSource="safarmarket";
      }
      if (parameters.yabex_id && parameters.utm_source && parameters.utm_source==="yabex"){
        metaId=parameters.yabex_id;
        metaSource="yabex";
      }

      if (metaId && metaSource && (LayoutConfig.safarmarket || LayoutConfig.yabex) && this.props.currencyCode ==="IRR"){
        url = `${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.ValidateFlight}?key=${parameters.key}&metaId=${metaId}&source=${metaSource}`;
      }
      axios({
          method:"post",
          url:url,
          cancelToken: source.token,
          headers:{...Header}
      }).then((response)=>{
        this.setState({...this.state,flightSelected:response.data.result})
      }).catch((error)=>{
        if(error.message && error.message !== "unmounted"){
          try{
            this.props.showError({visibility:true,content:error.response.data.error.message});
          }catch (e) {
            this.props.showError({visibility:true,content:"Internal Error,Please refresh the page"});
          }
        }
      })
  };

  fetchTravellers = async () => {
    if (process.env.REACT_APP_THEME !== "ITOURS_IR"){
      return;
    }
    this.setState({
      travellers:[],
      travellersLoading:true
    });
    
    const response = await getTravellers();

    this.setState({
      travellersLoading:false
    });

    if(response.data?.result?.items){
      this.setState({
        travellers: response.data.result.items
      });
    }    

  }

  refetchTravellersData = async () => {        
    const response = await getTravellers();
    if(response.data?.result?.items){
      this.setState({
        travellers: response.data.result.items
      });
    }
  }

  componentDidMount(){

    window.scrollTo(0, 0)

    let parameters = queryString.parse(this.props.location.search);
    if (parameters.key.toString().substr(0, 3) === "015") {
      this.setState({...this.state,showPersian:true})
    }
    this.validateFlight();

    //safarmarket:
    let smId;
    let cookies = decodeURIComponent(document.cookie).split(';');
    for (const item of cookies){
      if (item.includes("safarMarketId=")){
        smId=item.split("=")[1];
      }
    }
    if (LayoutConfig.safarmarket && this.props.currencyCode ==="IRR" && parameters.safarmarketId && parameters.utm_medium && parameters.utm_source ){
      this.setState({isFromSafarmarket:true});
      smId = parameters.safarmarketId;
      const expDate = new Date();
      expDate.setTime(expDate.getTime() + (168*60*60*1000));
      document.cookie = `safarMarketId=${parameters.safarmarketId}; expires=${expDate.toUTCString()};path=/`;
    }
    if (LayoutConfig.safarmarket && this.props.currencyCode ==="IRR" && smId) {
      this.props.setSafarmarketPixelValue(encodeURI(`https://safarmarket.com/api/v1/trace/pixel/itours/2/?smId=${smId}&bck=false`));
    }

    if(this.props.verification){
      this.fetchTravellers();
    }

  }

  componentWillUnmount(){
    this.props.setSafarmarketPixelValue(undefined);
    if (source) {
      source.cancel("unmounted");
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {flightSelected} = this.state;
    if (flightSelected && flightSelected!==prevState.flightSelected){
      if(flightSelected.isPriceChanged){
        this.props.showError({
          visibility:true,
          content:<div>
              {this.props.Dictionary.ThePriceOfThisFlightHasChanged}.
              <br/>
              <h5 className="normal-title">{this.props.Dictionary.newPrice}: <span dir="ltr"><Price currency={this.props.currencyCode}>
                  {flightSelected.pricedItinerary.airItineraryPricingInfo.itinTotalFare.totalFare}
                </Price></span>
              </h5>
            </div>,
          closable:true,
          buttonLabel:this.props.Dictionary.ok
        });
      }

      const data = flightSelected.pricedItinerary.airItinerary.originDestinationOptions;
      const lastRoute = data[data.length - 1];
      const expDate = lastRoute.flightSegments[lastRoute.flightSegments.length - 1].arrivalDateTime;

      let passengerInfo = [];
      const {LAN}=this.props;
      let defaultNationality = process.env.REACT_APP_THEME === 'NAMINTRAVELCOM'?"NO":process.env.REACT_APP_THEME ==='AYANAIRCOM'?"IQ":(LAN === "IR")?"IR":(LAN === "US")?"US":(LAN === "NO")?"NO":(LAN === "SE")?"SE":(LAN === "TR")?"TR":(LAN === "AR")?"SA":(LAN === "TR")?"TR":"NO"
      
      let extraBaggageIsSelected = false;
      let parameters = queryString.parse(this.props.location.search);
      if (parameters.extraBaggage){
        extraBaggageIsSelected=true;
      }
      let passengerCnt=0;
      flightSelected.pricedItinerary.airItineraryPricingInfo.ptC_FareBreakdown.forEach(
        (item) => {
          let birtDateMin;
          let birthDateMax;
          switch (item.passengerTypeQuantity.code){
            case "ADT":
              birtDateMin = moment().subtract(100, 'years').format();
              birthDateMax = moment().subtract(12, 'years').format();
              break;
            case "CHD":
              birtDateMin = moment().subtract(12, 'years').format();
              birthDateMax = moment().subtract(2, 'years').format();
              break;
            case "INF":
              birtDateMin = moment().subtract(2, 'years').format();
              birthDateMax = moment().format();
              break;
            default:
              birtDateMin = moment().subtract(100, 'years').format();
              birthDateMax = moment().format();
          }
          if (item.passengerBirthDateRange){
            birtDateMin = item.passengerBirthDateRange.minBirhDate;
            birthDateMax =item.passengerBirthDateRange.maxBirthDate;
          }
          for (let cnt = 1; cnt <= item.passengerTypeQuantity.quantity; cnt++) {
            passengerCnt++;
            let passitem = { 
              id:`passenger-${passengerCnt}`,
              type: item.passengerTypeQuantity.code,
              nationality:defaultNationality,
              birthDateRange:{min:birtDateMin,max:birthDateMax},
              services:{extraBaggage:extraBaggageIsSelected}
            };
            passengerInfo.push(passitem);
          }
        }
      );

      this.setState({
        ...this.state,
        passportExpMin:moment(expDate).add(1, "d"),
        passengerInformation:passengerInfo
      })

    }

    if (this.props.reserveId !== prevProps.reserveId) {
      this.setState({...this.state,reserveLoading:true});

      //safarMarket:
      let smId;
      let cookies = decodeURIComponent(document.cookie).split(';');
      for (const item of cookies){
        if (item.includes("safarMarketId=")){
          smId=item.split("=")[1];
        }
      }
      let parameters = queryString.parse(this.props.location.search);
      if (parameters.safarmarketId && parameters.utm_medium && parameters.utm_source ){
        smId = parameters.safarmarketId;
      }

      let reserveUrl = `/payment/reserve?reserveId=${this.props.reserveId}&type=flight`
      if (smId && LayoutConfig.safarmarket && this.props.currencyCode ==="IRR"){
        reserveUrl = `/payment/reserve?reserveId=${this.props.reserveId}&type=flight&smid=${smId}`;
      }
      this.props.history.push(reserveUrl);
    }
    if(this.props.verification && (this.props.verification !== prevProps.verification) && this.state.getPnrParams){
      let token = localStorage.getItem("userToken");
      this.setState({ ...this.state, reserveLoading: true });

      const params = {...this.state.getPnrParams};
      if(this.props.userData.id){
        params.reserver.userId = this.props.userData.id;
      }
      if(this.props.userData.userName){
        params.reserver.username = this.props.userData.userName;
      }
      this.props.getPassengerFlight(params, source.token ,token);
    }

    if(this.props.verification && !prevProps.verification){
      this.fetchTravellers();
    }
  }
  
  fixNumbers = str => {
    const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
    const arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
    if(typeof str === 'string'){
      for(let i=0; i<10; i++){
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
    }
    return str;
  };

  setPassengerNationality = (val,index) => {
    let updatedPassengerInformation = [...this.state.passengerInformation];
    updatedPassengerInformation[index].nationality=val;
    this.setState({
      ...this.state,
      passengerInformation:updatedPassengerInformation
    })
  }

  FormDOM = [];
  handleSubmit(event) {
    event.preventDefault();
    super.validateForm(event, () => {
      this.props.editMessage(false);
      let dataModelInput = this.state.DataInput;
      // dataModelInput.passengers.forEach((item)=>{
      //     if(item.gender==="true"){
      //         item.firstName="MR "+item.firstName;
      //     }else{
      //        item.firstName="MRS "+item.firstName;
      //     }
      // });


      let countryCode = dataModelInput.reserver.countryCallingCode;
      if (countryCode.substring(0, 2) === "00"){
        countryCode = "+" + countryCode.substring(2)
      }

      if(this.state.flightSelected.pricedItinerary.hasExtraBaggage){
        let updatedPassengers = [...dataModelInput.passengers];
        const passengerInformations = this.state.passengerInformation;
        for(let i=0 ; i <passengerInformations.length ; i++){
          if(passengerInformations[i].services.extraBaggage){
            updatedPassengers[i].hasExtraBaggage = true;
          }else{
            updatedPassengers[i].hasExtraBaggage = false;
          }
        }
        dataModelInput.passengers = updatedPassengers;
      }

      const editedPassengers = dataModelInput.passengers.map(item=>{
        const editingPassenger = {...item};
        if (editingPassenger.nationalId){
          editingPassenger.nationalId = this.fixNumbers(editingPassenger.nationalId);
        }
        if (editingPassenger.passportNumber){
          editingPassenger.passportNumber = this.fixNumbers(editingPassenger.passportNumber);
        }
        return editingPassenger;
      });
      const updatedData = {...dataModelInput};
      updatedData.passengers = editedPassengers;

      const updatedReserver = {...updatedData.reserver};
      updatedReserver.phoneNumber = countryCode +this.fixNumbers(updatedData.reserver.phoneNumber);
      if (process.env.REACT_APP_THEME ==='NAMINTRAVELCOM') {
        updatedReserver.username = dataModelInput.reserver.email;
      }else{
        updatedReserver.username = this.props.userData?.userName ? this.props.userData.userName : countryCode +this.fixNumbers(updatedData.reserver.phoneNumber);
      }
      if(this.props.userData?.id){
        updatedReserver.userId = this.props.userData.id;
      }
      updatedData.reserver = updatedReserver;


      let token = localStorage.getItem("userToken");
      if (process.env.REACT_APP_SERVER_LOCALSERVER !== 'itours.ir___'){
        this.setState({ ...this.state, reserveLoading: true });
        if (token){
          this.props.getPassengerFlight(updatedData, source.token ,token);
        }else{
          this.props.getPassengerFlight(updatedData, source.token);
        }
      }else if (token){
        this.setState({ ...this.state, reserveLoading: true });
        this.props.getPassengerFlight(updatedData, source.token, token);
      }else{
        this.setState({ ...this.state, getPnrParams: updatedData });
        this.props.openIrLoginModal();
      }
    });
  }
  onChangeValidation(event, type, maxLength) {
    if (maxLength) {
      if (event.target.value.length > maxLength) {
        const prevVal = event.target.value.substring(0, maxLength);
        event.target.value = prevVal;
      }
    }
    let regex;
    switch (type) {
      case 'number':
        regex = /^[0-9۰-۹٠-٩]*$/;
        break;
      case 'enAlphabet':
        regex = /^[a-zA-Z ]*$/;
        break;
      case 'faAlphabet':
        regex = /^[\u0600-\u06FF ]*$/;
        break;
      case 'email':
        regex = /[^\u0600-\u06FF\s]/;
        break;
      case 'passport':        
        regex = /^[a-zA-Z0-9۰-۹٠-٩]*$/;
        break;        
      default:
        regex = /^[a-zA-Z ]*$/;
    }

    if (!regex.test(event.target.value)) {
      const prevVal = event.target.value.substring(0, event.target.value.length - 1);
      if (regex.test(prevVal)) {
        event.target.value = prevVal;
      } else {
        event.target.value = '';
      }
    }else{
      if(type === "passport"){
        event.target.value = event.target.value.toUpperCase();
      }
    }
  }

  ifEnglish = string => {
    const regex = /^[a-zA-Z ]*$/;
    if (!regex.test(string)){
      return "";
    }
    return string;
  }

  render(){

    const {flightSelected,showPersian,passengerInformation,passportExpMin,calculatedTotalPriceWithExtraBaggage} = this.state;
    const { Dictionary, LAN, userData } = this.props;
    const { Step } = Steps;
    
    let userInfoStatus = false;
    if (userData && userData.id){
       userInfoStatus = true;
    }
    
    const updatedUserData = userInfoStatus ? {
      ...userData,
      firstName:this.ifEnglish(userData.firstName),
      lastName:this.ifEnglish(userData.lastName)
    } : undefined;

    const specialDietsList = [
      {value:0,title:"No special diet"},
      {value:1,title:"Vegetarian"},
      {value:2,title:"Lactose free"},
      {value:3,title:"Gluten free"},
      {value:4,title:"Kosher meal"},
      {value:5,title:"Muslim meal"}
    ];
    let userPhoneNumber = undefined;
    const formIcon = LayoutConfig.themeClassName === 'naminTravel-theme' ? formIconPink : formIconOrange;

    let hasIAWarningModal = false;
    if (flightSelected && LAN === "IR"){
      const airlines = flightSelected.pricedItinerary?.airItinerary?.originDestinationOptions?.flatMap(originDestinationOption => originDestinationOption.flightSegments.map(segment => segment.marketingAirline?.code));
      if(airlines.includes("IA")){
        hasIAWarningModal = true;
      }
    }
    
    const isStyle3 = LayoutConfig.themeClassName === 'style-3';

    return(<div>
      {!isStyle3 && <FetchProgress compeleted={!!flightSelected} />}
        {!flightSelected ?
        (
          <>
            {isStyle3 ? (
              <div className="page-container padding-top">
                <div className="steps-skleton padding-top margin-bottom-large hidden-xsmall">
                  <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                  <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-center" />
                  <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end" />
                </div>
                <div className="bg-white bold box-border padding-v-20 padding-h-15 margin-bottom">
                  <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                </div>
                <div className="float-row margin-bottom-large">

                  <div className="col-small-12 col-medium-8 margin-bottom">

                    <div className="box-border bg-white card-padding margin-bottom" >

                      <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton margin-bottom-large margin-top" />
                      <Skeleton active paragraph={{ rows: 0 }} title={{ width: "100%" }} className="one-line-skeleton margin-bottom-large" />

                      <div className="float-row">
                        {[1, 2, 3, 4].map(item => (
                          <div className="col-small-12 col-medium-6" key={item}>
                            <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton margin-bottom" />
                            <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton full-width-button-skeleton margin-bottom" />
                          </div>
                        ))}
                      </div>
                    </div>


                    <div className="box-border bg-white card-padding margin-bottom" >

                      <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton margin-bottom-large margin-top" />
                      <Skeleton active paragraph={{ rows: 0 }} title={{ width: "100%" }} className="one-line-skeleton margin-bottom-large" />

                      <div className="float-row">
                        {[1, 2, 3, 4, 5, 6].map(item => (
                          <div className="col-small-12 col-medium-6" key={item}>
                            <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton margin-bottom" />
                            <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton full-width-button-skeleton margin-bottom" />
                          </div>
                        ))}
                      </div>
                    </div>

                  </div>

                  <div className="col-small-12 col-medium-4 margin-bottom hidden-xsmall hidden-small">
                    <div className="box-border bg-white card-padding">

                      <div className="justify-between margin-bottom-large">
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton button-skeleton text-end" />
                      </div>

                      <div className="route-skeleton margin-bottom">
                        <span className="airline" />
                        <Skeleton active paragraph={{ rows: 1 }} className="origin" />
                        <Skeleton active paragraph={{ rows: 1 }} className="origin text-end" />
                      </div>

                      <div className="route-skeleton">
                        <span className="airline" />
                        <Skeleton active paragraph={{ rows: 1 }} className="origin" />
                        <Skeleton active paragraph={{ rows: 1 }} className="origin text-end" />
                      </div>

                      <hr className="margin-top margin-bottom" />

                      <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton margin-bottom" />

                      <div className="justify-between margin-bottom-large">
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end" />
                      </div>

                      <hr className="margin-top margin-bottom" />


                      <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton margin-bottom" />

                      <hr className="margin-top margin-bottom" />

                      <div className="justify-between margin-bottom-large">
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end" />
                      </div>

                      <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton full-width-button-skeleton" />

                    </div>
                  </div>

                </div>
              </div>
            ) : (
              <Loading fullPage description={Dictionary.CheckingFlightInformation + " ..."} />
            )}
          </>
        )
        :this.state.reserveLoading?
          <Loading fullPage/>
        :
        <div className="flight getpassengers getpassenger-page page-padding no-padding-mobile">
          {!!hasIAWarningModal && (
            <AirlineWarning visibility={this.state.IAWarningModalVisibility} close={() =>{this.setState({IAWarningModalVisibility:false})}} />
          )}
            <div className="page-container no-padding-mobile flightdetails ">
                <div>
                    <Steps current={1}>
                        <Step title={Dictionary.chooseFlightsOrHotels} />
                        <Step title={Dictionary.enterThePassengersInformation} />
                        <Step title={Dictionary.paymentTypeOptions} />
                    </Steps>
                    { this.state.isFromSafarmarket && <SafarmarketWelcome />}
                    {/* {this.state.isFromSafarmarket && process.env.REACT_APP_THEME === "ITOURS_IR" && <CancelWarningModal /> } */}
                </div>
              <div className={`bg-white card-padding bold box-border ${isStyle3?"blue-bordered-message-block":"message-block error-message"}`}>
                {LAN === "IR" ?
                  <div className="font-18">
                    برای مشاهده اطلاعات بار و قوانین پرواز روی
                    <span className="summary-detail-link" onClick={() => {
                      this.setState({
                        detailsIsOpen: true
                      })
                    }}> جزییات پرواز </span>
                    کلیک کنید.
                  </div>
                  :
                  Dictionary.ClickOnFlightDetailsToViewBaggageInformationAndFlightRules
                }
                </div>
              <form
                id={"reserveForm"}
                onSubmit={this.handleSubmit.bind(this)}
                ref={(node) => (this.FormDOM = node)}
                noValidate
              >
                <StickySides 
                  main={(
                    <>
                      <div className="box-border bg-white passengers-informations">
                        {/* {!this.props.userData.id && (
                          <div className="login-invite">
                            <strong>{Dictionary.alreadyAnItoursMember}</strong>
                            {LAN === "IR" ? (
                              <div className="content">
                                <span> برای رزرو سریعتر </span>
                                <span
                                  className="content-link"
                                  onClick={() => {
                                    this.props.SwitchAccountWindow(false);
                                    this.props.toggleAccountWindow();
                                  }}
                                >
                                  وارد شوید
                                </span>
                                .
                              </div>
                            ) : LAN === "AR" ? (
                              <div className="content">
                                <span
                                  className="content-link"
                                  onClick={() => {
                                    this.props.SwitchAccountWindow(false);
                                    this.props.toggleAccountWindow();
                                  }}
                                >
                                  تسجيل الدخول{" "}
                                </span>
                                <span> للحجز أسرع</span>.
                              </div>
                            ) : (
                              <div className="content">
                                <span
                                  className="content-link margin-end-half"
                                  onClick={() => {
                                    this.props.SwitchAccountWindow(false);
                                    this.props.toggleAccountWindow();
                                  }}
                                >
                                  {Dictionary.signIn}
                                </span>
                                <span> {Dictionary.now} </span>
                                <span> {Dictionary.and} </span>
                                <span> {Dictionary.speedUpYourBooking}!</span>
                              </div>
                            )}
                          </div>
                        )} */}
                        <div className={"get-passenger-form-holder padding-20"}>
                          <h5 className="title">
                            {Dictionary.enterContactDetails}
                          </h5>
                          <div className="icon-hint-holder">

                            {isStyle3 ? (
                              <FormIcon />
                            ) : (
                              <img src={formIcon} className="icon" alt="" />
                            )}

                            <div className="hint-inner">
                              {
                                Dictionary.BookingInformationsWillbeSentToThisNumberOrEmail
                              }
                            </div>
                          </div>
                          {flightSelected && flightSelected.pricedItinerary && <input
                            value={flightSelected.pricedItinerary.key}
                            type="hidden"
                            name={"key"}
                          />}
                          <div className="float-row">
                            <div className="col-small-12 col-medium-6">
                              <div className="form-group required">
                                <label className="form-label">
                                  {Dictionary.firstNameEn}
                                </label>
                                <div className="select-input-pair-holder">
                                  <select
                                    className="form-select"
                                    name={"reserver.gender"}
                                    defaultValue={
                                      userInfoStatus ? userData.gender : true
                                    }
                                  >
                                    <option value={true}>
                                      {Dictionary.male}
                                    </option>
                                    <option value={false}>
                                      {Dictionary.female}
                                    </option>
                                  </select>
                                  <input
                                    onChange={e => {this.onChangeValidation(e,"enAlphabet")}}
                                    data-validator-type="alphabet"
                                    className="form-input"
                                    data-validator={true}
                                    placeholder={Dictionary.firstName}
                                    name="reserver.firstName"
                                    type="text"
                                    defaultValue={updatedUserData?.firstName || ""}
                                  />
                                </div>
                                <Validation
                                  model={this.state}
                                  feild="ValidatorList"
                                  name="reserver.firstName"
                                />
                              </div>
                            </div>
                            <div className="col-small-12 col-medium-6">
                              <div className="form-group required">
                                <label className="form-label">
                                  {Dictionary.lastNameEn}
                                </label>
                                <input
                                  onChange={e => {this.onChangeValidation(e,"enAlphabet")}}
                                  data-validator-type="alphabet"
                                  className="form-input"
                                  data-validator={true}
                                  placeholder={Dictionary.lastName}
                                  name="reserver.lastName"
                                  type="text"
                                  defaultValue={updatedUserData?.lastName || ""}
                                />
                                <Validation
                                  model={this.state}
                                  feild="ValidatorList"
                                  name="reserver.lastName"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="float-row">
                            <div className="col-small-12 col-medium-6">
                              <div className="form-group required">
                                <label htmlFor="" className="form-label">
                                  {Dictionary.email}
                                </label>
                                <input
                                  onChange={e => {this.onChangeValidation(e,"email")}}
                                  data-validator={true}
                                  className="form-input placeholder-right"
                                  placeholder={Dictionary.enterYourEmail}
                                  name="reserver.email"
                                  type="email"
                                  defaultValue={
                                    userInfoStatus ? userData.emailAddress : ""
                                  }
                                />
                                <Validation
                                  model={this.state}
                                  feild="ValidatorList"
                                  name="reserver.email"
                                />
                              </div>
                            </div>
                            <div className="col-small-12 col-medium-6">
                              <div className="form-group required" >
                                {LAN !== "IR" ? (
                                  <label htmlFor="" className="form-label">
                                    {Dictionary.phoneNumber}({Dictionary.optional})
                                  </label>
                                ) : (
                                  <label htmlFor="" className="form-label">
                                    {Dictionary.phoneNumber}
                                  </label>
                                )}
                                <div
                                  className="select-input-pair-holder "
                                  dir="ltr"
                                >
                                  <select
                                    className="form-select"
                                    name={"reserver.countryCallingCode"}
                                    dir="ltr"
                                  >
                                    {CountryPostalCode.sort((a,b)=>{
                                      let x = a.code.toLowerCase();
                                      let y = b.code.toLowerCase();
                                      if (x < y) {return -1;}
                                      if (x > y) {return 1;}
                                      return 0;
                                    }).map((item) => {
                                      let seleceted = false;
                                      if (userData && userData.phoneNumber){
                                        let code = item.value;
                                        if (code.substring(0, 2) === "00"){
                                          code = "+" + code.substring(2);
                                        }
                                        if (userData.phoneNumber.includes(code)){
                                          seleceted=true;
                                          let phone_Number = userData.phoneNumber.replace(code,'');
                                          userPhoneNumber=phone_Number
                                        }
                                      }else{
                                        if(
                                          (item.value === "0098" && LAN === "IR") 
                                          || (item.value === "0090" && LAN === "TR") 
                                          || (item.value === "00971" && LAN === "AR" && (process.env.REACT_APP_THEME !=='AYANAIRCOM')) 
                                          || (item.code === "Norway (+47)" && LAN !== "AR" && LAN !== "IR" && process.env.REACT_APP_THEME !=='AYANAIRCOM')
                                          || (item.value === '00964' && process.env.REACT_APP_THEME ==='AYANAIRCOM')
                                          ){
                                          seleceted=true;
                                        }
                                      }
                                      return(<option
                                        key={item.code}
                                        value={item.value}
                                        selected={seleceted }
                                      >
                                        {item.code}
                                      </option>)
                                    })}
                                  </select>
                                  <input
                                    onChange={e => {this.onChangeValidation(e,"number")}}
                                    className="form-input placeholder-right"
                                    data-validator={true}
                                    placeholder="9XXXXXXXXX"
                                    defaultValue={userPhoneNumber ? userPhoneNumber : undefined}
                                    name="reserver.phoneNumber"
                                    type="tel"
                                  />
                                </div>
                                <Validation
                                  model={this.state}
                                  feild="ValidatorList"
                                  name="reserver.phoneNumber"
                                />
                              </div>
                            </div>
                          </div>
                          {showPersian && (
                            <div className="float-row">
                              <div className="col-small-12 col-medium-6">
                                <div className="form-group">
                                  <div className="select-input-pair-holder">
                                    <img
                                      src={flightSelected.captchaLink}
                                      alt={"captcha"}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-small-12 col-medium-6">
                                <div className="form-group">
                                  <input
                                    data-validator-type="number"
                                    className="form-input"
                                    data-validator={true}
                                    placeholder={Dictionary.captchaCode}
                                    name="captchaResult"
                                    type="text"
                                    defaultValue={null}
                                  />
                                  <Validation
                                    model={this.state}
                                    feild="ValidatorList"
                                    name="captchaResult"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="get-passenger-form-holder padding-v-20 box-border bg-white margin-bottom">
                        <div className="padding-h-20">
                          <h5 className="title">
                            {Dictionary.enterTravellerDetails}
                          </h5>
                          <div className="icon-hint-holder">
                          
                            {isStyle3 ? (
                              <FormIcon />
                            ) : (
                              <img src={formIcon} className="icon" alt="" />
                            )}

                            <div className="hint-inner">
                              <span>
                                {
                                  Dictionary.EnterYourDetailsAsTheyAppearOnYourPassport
                                }
                              </span>
                              <span> {Dictionary.useEnglishOnly}.</span>
                            </div>
                          </div>
                        </div>
                        <div className="passengers-information">
                          {(passengerInformation.length > 0) &&
                            passengerInformation.map(
                              (item, index) => {
                                return (
                                  <PassengerItem
                                    key={index}
                                    travellers = {this.state.travellers}
                                    index = {index}
                                    item  = {item}
                                    onChangeValidation = {this.onChangeValidation}
                                    state = {this.state}
                                    setPassengerNationality = {this.setPassengerNationality}
                                    showPersian={showPersian}
                                    flightSelected={flightSelected}
                                    passengerInformation={passengerInformation}
                                    passportExpMin={passportExpMin}
                                    openIrLoginModal = {this.props.openIrLoginModal}
                                    travellersLoading = {this.state.travellersLoading}
                                    refetchTravellersData={this.refetchTravellersData}
                                  />
                                );
                              }
                            )}
                        </div>
                        <div className="border-top padding-h-20">
                          <div className="checkbox-holder has-link">
                            <input
                              className="custom-checkbox"
                              type="checkbox"
                              data-validator={true}
                              name={`acceptRuls`}
                            />
                            <label className="checkbox-label font-14">
                              {LAN === "IR" ? (
                                <>
                                  <a href={"/terms"} className="content-link">
                                    قوانین و مقررات آیتورز{" "}
                                  </a>
                                  را خوانده ام و با آن موافق هستم.
                                </>
                              ) : (
                                <>
                                  {Dictionary.iHaveReadAndAgreeToThe}
                                  <Link to={"/terms"} className="content-link" target="_blank">
                                    {Dictionary.termsAndConditions}
                                  </Link>
                                </>
                              )}
                            </label>
                            <Validation
                              model={this.state}
                              feild="ValidatorList"
                              name={`acceptRuls`}
                            />
                          </div>
                        </div>
                      </div>
                      {flightSelected.pricedItinerary.hasExtraBaggage && (1+1==="extraBaggageDeactive") &&
                      <div className="box-border bg-white get-passenger-form-holder padding-20 margin-bottom">
                        <h5 className="title">
                          <span className="itours-icon icon-large baggage-circle margin-end-half" />
                          {Dictionary.extraBaggage}
                        </h5>
                        <p>{Dictionary.extraBaggageP1} {Dictionary.extraBaggageP2}</p>
                        <h5 className="font-16 semi-bold">{Dictionary.orderingExtraBaggage}</h5>
                        <div className="checkbox-holder">
                          <input
                            className="custom-checkbox"
                            type="checkbox"
                            // %%%  checked={this.state.allPassengersHaveExtraBaggage}
                            // %%% onChange={this.toggleAllPassengersExtraBaggage}
                          />
                          <label className="checkbox-label font-14">
                            {Dictionary.AllTravellers}
                          </label>
                        </div>
                        {passengerInformation.length &&
                            passengerInformation.map((passengerItem,passengerIndex)=><div key={passengerIndex}>
                            <div className="checkbox-holder">
                            <input
                              className="custom-checkbox"
                              type="checkbox"
                              checked ={passengerItem.services.extraBaggage}
                              // %%% onChange={(e)=>this.changeExtraBaggage(e,passengerIndex)}
                            />
                            <label className="checkbox-label font-14">
                              {Dictionary.passenger + " " + (+passengerIndex+1)}
                              {" "}
                              ({(passengerItem.type === "ADT") ? Dictionary.adult
                              :(passengerItem.type === "CHD") ? Dictionary.child
                              :(passengerItem.type === "INF") ? Dictionary.infant
                              :passengerItem.type})

                              
                              <span dir="ltr" className="margin-start-large">
                                +<Price currency={this.props.currencyCode}>
                                  {flightSelected.pricedItinerary.airItineraryPricingInfo.ptC_FareBreakdown.filter(typeItem => typeItem.passengerTypeQuantity.code === passengerItem.type)[0].passengerFare.extraBaggage}
                                </Price>
                              </span>
                              
                            </label>
                          </div>
                        </div>)
                        }

                        <div className="itours-table-holder responsive-table margin-top">
                          <table className="itours-table text-center bordered">
                            <thead>
                              <tr>
                                <th>{Dictionary.origin}</th>
                                <th>{Dictionary.destination}</th>
                                <th>{Dictionary.baggage}</th>
                                <th> {Dictionary.extraBaggage}</th>
                              </tr>
                            </thead>
                            <tbody>
                            {flightSelected.pricedItinerary.airItinerary.originDestinationOptions.map((itineraryItem,itineraryIndex)=>
                              itineraryItem.flightSegments.map((segmentItem,segmentIndex)=><tr key={itineraryIndex+"-"+segmentIndex}>
                                <td>
                                  <b>{segmentItem.departureAirport.cityName}</b> -{" "}
                                  <small>
                                    {segmentItem.departureAirport.countryName}
                                  </small>
                                </td>
                                <td><b>{segmentItem.arrivalAirport.cityName}</b> -{" "}
                                  <small>
                                    {segmentItem.arrivalAirport.countryName}
                                  </small>
                                </td>
                                <td className="baggage-cell">
                                  {segmentItem.baggageInformation.map(
                                    (baggageItem, baggageIndex) => (
                                      <div key={baggageIndex} className="no-wrap">
                                        <span dir="ltr" className="margin-end-half"> {baggageItem.baggageAllowance} {baggageItem.unitType} </span> <span> {Dictionary.for} </span>
                                        {baggageItem.passengerType === "ADT" && (
                                          <span>{Dictionary.adult}</span>
                                        )}
                                        {baggageItem.passengerType === "CHD" && (
                                          <span>{Dictionary.child}</span>
                                        )}
                                        {baggageItem.passengerType === "INF" && (
                                          <span>{Dictionary.infant}</span>
                                        )}
                                      </div>
                                    )
                                  )}                                
                                </td>
                                <td className="baggage-cell">
                                  {segmentItem.extraBaggageInformation.map(
                                    (baggageItem, baggageIndex) => (
                                      <div key={baggageIndex} className="no-wrap">
                                        <span dir="ltr" className="margin-end-half"> {baggageItem.baggageAllowance} {baggageItem.unitType} </span> <span> {Dictionary.for} </span>
                                        {baggageItem.passengerType === "ADT" && (
                                          <span>{Dictionary.adult}</span>
                                        )}
                                        {baggageItem.passengerType === "CHD" && (
                                          <span>{Dictionary.child}</span>
                                        )}
                                        {baggageItem.passengerType === "INF" && (
                                          <span>{Dictionary.infant}</span>
                                        )}
                                      </div>
                                    )
                                  )}                                  
                                </td>
                              </tr>)
                            )}
                            </tbody>
                          </table>
                        </div>

                      </div>
                      }

                      {process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' &&
                      <div className="box-border bg-white get-passenger-form-holder padding-20">
                        <h5 className="title">
                          {Dictionary.specialFood}
                        </h5>
                        <p><span className="itours-icon icon-large fork-knife-circle margin-end-half" /> {Dictionary.specialDietP}.</p>

                        <div className="float-row">
                          {passengerInformation.length &&
                              passengerInformation.map((passengerItem,passengerIndex)=>(passengerItem.type !== "INF") ? <div key={passengerIndex} className="col-xsmall-12 col-small-6 col-large-4 special-diet-passenger-item-col">
                                <div className="diet-passenger-item-inner">
                                  <h5 className="passenger-title">
                                    {Dictionary.passenger + " " + (+passengerIndex+1)}
                                    {" "}
                                    <span className="small">({(passengerItem.type === "ADT") ? Dictionary.adult
                                    :(passengerItem.type === "CHD") ? Dictionary.child
                                    :(passengerItem.type === "INF") ? Dictionary.infant
                                    :passengerItem.type})</span>
                                  </h5>
                                  {specialDietsList.map((dietItem,dietIndex)=><label key={dietItem.title} className='radio-btn-label type-2'>
                                    <input type="radio" 
                                      name={`passengers[${passengerIndex}].mealType`}
                                      value={dietItem.value}
                                    />
                                    <span className="radio-btn-label-text">{dietItem.title}</span>
                                  </label>)}
                                </div>
                              {/* <div className="checkbox-holder">
                              <input
                                className="custom-checkbox"
                                type="checkbox"
                                name={`allExtraBaggageChecked`}
                                checked ={passengerItem.services.extraBaggage}
                                onChange={(e)=>this.changeExtraBaggage(e,passengerIndex)}
                              />
                              <label className="checkbox-label font-14">


                                
                                <span dir="ltr" className="margin-start-large">
                                  +<Price currency={this.props.currencyCode}>
                                    {flightSelected.pricedItinerary.airItineraryPricingInfo.ptC_FareBreakdown.filter(typeItem => typeItem.passengerTypeQuantity.code === passengerItem.type)[0].passengerFare.extraBaggage}
                                  </Price>
                                </span>
                                
                              </label>
                            </div> */}
                          </div>
                          :null)
                          }
                        </div>

                      </div>
                      }
                    </>
                  )}
                  aside={(
                    <FlighSummary
                    hasBackLink
                    detailsIsOpen={this.state.detailsIsOpen}
                    closeDetailsModal={()=>{this.setState({detailsIsOpen:false})}}
                    flightType={flightSelected.pricedItinerary.flightType}
                    isDomestic={this.props.isDomestic}
                    showFlightAllDetails
                    hasExtraBaggage={flightSelected.pricedItinerary.hasExtraBaggage}
                    passengerInformation={passengerInformation}
                    supplierKey={flightSelected.pricedItinerary.key}
                    showButton={true}
                    //disabledButton={(process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' || process.env.REACT_APP_THEME ==='AYANAIRCOM')? true :false}
                    model={
                      flightSelected.pricedItinerary.airItinerary
                        .originDestinationOptions
                    }
                    currencyCode={this.props.currencyCode}
                    fareInfo={
                      flightSelected.pricedItinerary.airItineraryPricingInfo
                        .ptC_FareBreakdown
                    }
                    totalPrice={
                      flightSelected.pricedItinerary.airItineraryPricingInfo
                        .itinTotalFare.totalFare
                    }
                    itinTotalFare ={flightSelected.pricedItinerary.airItineraryPricingInfo.itinTotalFare}
                    calculatedTotalPriceWithExtraBaggage={calculatedTotalPriceWithExtraBaggage}
                    totalPriceWithExtraBaggage = {
                      flightSelected.pricedItinerary.airItineraryPricingInfo
                      .itinTotalFare.totalFareWithExtraBaggage || undefined
                    }
                    feild={"passengerFare"}
                  />
                  )}
                />
              </form>
            </div>
        </div>
        }
    </div>
    )
  }
}

const dispatchToProps=(dispatch)=>({
  showError:(param)=>dispatch(showError(param)),
  getPassengerFlight: (param,sourceToken,token) => {dispatch(getPassengerFlight(param,sourceToken,token));},
  editMessage:(param)=>dispatch(editMessage(param)),
  setSafarmarketPixelValue:(data)=>dispatch(setSafarmarketPixel(data)),
  openIrLoginModal: () =>{dispatch(showAccountWindowIR("usernameModal"))}
});

const stateToProps = (state) => ({
respond: state.VisualData.Responded,
userData: state.UserData.userData,
currencyCode: state.UserData.Currency,
reserveId: state.FlightSearch.reserveId,
Dictionary: state.VisualData.Dictionary,
LAN: state.VisualData.LAN,
verification:state.VisualData.Verification
});

const routerBind = withRouter(FlightGetPassenger);
export default connect(stateToProps, dispatchToProps)(routerBind);