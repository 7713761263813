import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FlightSearchForm from "../../components/Flight/SearchForm";
import HotelSearchForm from "../../components/Accomodation/SearchForm";
import PackageSearchForm from "../../components/Package/PackageSearchForm";
import DomesticHotelSearchForm from "../domesticHotel/DomesticHotelSearchForm";
import LayoutConfig from "../../Config/layoutConfig";
import SearchBoxTab from '../Common/SearchBoxTab';
import FlightHomeTop from "../../components/TOF/Flight-Home-Top-TOF";
import HotelHomeTop from "../../components/TOF/Hotel-Home-Top-TOF";
import PackageHomeTop from "../../components/TOF/Package-Home-Top-TOF";
import style3Airplane from '../../image/style3/airplane.png';
// import CancelWarningModal from "../Flight/CancelWarningModal";

const Banner = (props) =>{
  
  const { Dictionary ,LAN} = props;

  let BannerImage;

  if(props.searchType === 'flight' || props.searchType === 'foreignFlight' ){
    BannerImage = LayoutConfig.homeFlightBanner || LayoutConfig.homeBanner
  }else if (props.searchType === 'hotel' || props.searchType === "domesticHotel"){
    BannerImage = LayoutConfig.homeHotelBanner || LayoutConfig.homeBanner
  }else if (props.searchType === 'package'){
    BannerImage = LayoutConfig.homePackageBanner || LayoutConfig.homeBanner
  }else{
    BannerImage = LayoutConfig.homeBanner
  }

  let wrapperClass = "home-search-section relative";

  let styleOfBanner = {
    backgroundImage:`url("${BannerImage}")`
  }

  if (LayoutConfig.themeClassName === "style-2"){
    styleOfBanner={};
    wrapperClass = "style-2-search-section relative";
  }

  if (LayoutConfig.themeClassName === 'naminTravel-theme'){
    wrapperClass = "home-search-section relative has-dark-layer";
  }

  const padidehBanner =(
    <div>

    <div className="page-container mobile-tab-hotel-flight">
      <div className="clearfix home-tabs-holder">
        {props.searchType==="flight" ?     
          <>                
            {LayoutConfig.hasDomesticFlight?
              <>
              <button type="button" className="home-tab home-active-tab">
                <span className="itours-icon top-plane-blue margin-end-light hidden-medium hidden-large" />
                {Dictionary.domesticFlight}
              </button>
              <Link to="/foreignflight" className="home-tab">
                <span className="itours-icon top-plane-white margin-end-light hidden-medium hidden-large" />
                {Dictionary.foreignFlight}
              </Link>                                        
              </>
              :
              <button type="button" className="home-tab home-active-tab">
                <span className="itours-icon top-plane-blue margin-end-light hidden-medium hidden-large" />
                {Dictionary.foreignFlight}
              </button>
            }


            <Link to="/hotel" className="home-tab">
              <span className="itours-icon hotel-white margin-end-light hidden-medium hidden-large" />
              {Dictionary.foreignHotel}
            </Link>

            <Link to="/package" className="home-tab">
              <span className="itours-icon package-white margin-end-light hidden-medium hidden-large" /> 
              {Dictionary.package}
            </Link>  
            
          </>
        :props.searchType==="foreignFlight"?
          <>                

            <Link to="/" className="home-tab">
              <span className="itours-icon top-plane-white margin-end-light hidden-medium hidden-large" />
              {Dictionary.domesticFlight}
            </Link> 

            <button type="button" className="home-tab home-active-tab">
              <span className="itours-icon top-plane-blue margin-end-light hidden-medium hidden-large" />
              {Dictionary.foreignFlight}
            </button>  


          <Link to="/hotel" className="home-tab">
            <span className="itours-icon hotel-white margin-end-light hidden-medium hidden-large" />
            {Dictionary.foreignHotel}
          </Link>

          <Link to="/package" className="home-tab">
            <span className="itours-icon package-white margin-end-light hidden-medium hidden-large" /> 
            {Dictionary.package}
          </Link>  
          
        </>                    
        :props.searchType==="hotel" ?
          <>
            {LayoutConfig.hasDomesticFlight ?
              <Link to="/" className="home-tab">
                <span className="itours-icon top-plane-white margin-end-light hidden-medium hidden-large" />
                {Dictionary.domesticFlight}
              </Link>
            :null}                  
            <Link to={LayoutConfig.hasDomesticFlight?"/foreignflight":"/"} className="home-tab">
              <span className="itours-icon top-plane-white margin-end-light hidden-medium hidden-large" />
              {Dictionary.foreignFlight}
            </Link>

            <button type="button" className="home-tab home-active-tab">
              <span className="itours-icon hotel-blue margin-end-light  hidden-medium hidden-large" />
              {Dictionary.foreignHotel}
            </button>  
            <Link to="/package" className="home-tab">
              <span className="itours-icon package-white margin-end-light hidden-medium hidden-large" /> 
              {Dictionary.package}
            </Link>                    
          </>
        :props.searchType==="package" ?
        <>
          {LayoutConfig.hasDomesticFlight ?
            <Link to="/" className="home-tab">
              <span className="itours-icon top-plane-white margin-end-light hidden-medium hidden-large" />
              {Dictionary.domesticFlight}
            </Link>
          :null}                  
          <Link to={LayoutConfig.hasDomesticFlight?"/foreignflight":"/"} className="home-tab">
            <span className="itours-icon top-plane-white margin-end-light hidden-medium hidden-large" />
            {Dictionary.foreignFlight}
          </Link>

          <Link to="/hotel" className="home-tab">
            <span className="itours-icon hotel-white margin-end-light hidden-medium hidden-large" />
            {Dictionary.foreignHotel}
          </Link>

          <button type="button" className="home-tab home-active-tab">
            <span className="itours-icon package-blue margin-end-light hidden-medium hidden-large" /> 
            {Dictionary.package}
          </button>                       
        </>                      
        :null}
      </div>
    </div>

    {props.searchType==="flight" ? 
      <FlightHomeTop 
        domesticFlight={LayoutConfig.hasDomesticFlight} 
        handleLink={() => this.handleLink(74961, "Phuket")}
      />
    :props.searchType === "foreignFlight" ?
      <FlightHomeTop
        handleLink={() => this.handleLink(74961, "Phuket")}
      />
    :props.searchType==="hotel" ?
      <HotelHomeTop handleLink={() => this.handleLink(74961, "Phuket")}/>
    :props.searchType ==="package" ? 
      <PackageHomeTop handleLink={() => this.handleLink(74961, "Phuket")}/>
    :null}              
  </div>
  );

  const mainSearchBlock = (
    <div id='search_block' className={(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') ? "naminTravel-search-block" : ""}>
      
        {(LayoutConfig.themeClassName === 'style-3' && props.searchType==="package") ? (
          <div className="page-container relative hidden-medium hidden-small hidden-xsmall ">
            <img src={style3Airplane} className="style3-airplane" alt={Dictionary.flight} />
          </div>
        ): null}

    {LayoutConfig.themeClassName === 'style-2' || <SearchBoxTab withContainer searchType={props.searchType} />}
    <div className="search-form-wrapper">
      <div className="page-container">
        <div className="search-form-inner-wrapper">
          {props.searchType==="flight" ? 
            <FlightSearchForm domesticFlight={LayoutConfig.hasDomesticFlight} isHome />
          :props.searchType === "foreignFlight" ?
            <FlightSearchForm isHome />
            :props.searchType==="hotel" ?
              <HotelSearchForm isHome collapseMode={false} fixedSearchHolder={false} />
            :props.searchType==="domesticHotel" ?
              <DomesticHotelSearchForm isHome collapseMode={false} fixedSearchHolder={false} />
          :props.searchType ==="package" ? 
            <PackageSearchForm collapseMode={false} />
          :null}
        </div>
      </div>
    </div>
  </div>
  );

  // const flightCancelationWarningIsclosed = window.sessionStorage.getItem("hideFlightCancelationWarning") === "true";
  
  return(
    <>

        <div className={wrapperClass} style={styleOfBanner}>

          {/* {!!(!flightCancelationWarningIsclosed && process.env.REACT_APP_THEME === "ITOURS_IR" ) && <CancelWarningModal />} */}

          {LayoutConfig.themeClassName === "padide-com-theme"  ? 
            padidehBanner
          :
            <>
              {LayoutConfig.themeClassName === 'naminTravel-theme' ?
                <div>
                  <div className="page-container relative padding-top hidden-xsmall hidden-small">
                    <h2 className="namin-banner-slogan"> {Dictionary.letsBookYourNextTrip}! </h2>
                    <div className="namin-banner-subslogan">{Dictionary.ChooseFromOver2millionHotelsAnd540Airlines}</div>
                  </div>
                </div>
               : LayoutConfig.themeClassName === 'ayanair-theme' ? (
                <div className="page-container relative padding-top hidden-xsmall hidden-small">
                  <h2 className="namin-banner-slogan">
                    {Dictionary.letsBookYourNextTrip}! 
                  </h2>
                  <div className="namin-banner-subslogan">
                  {LAN === "US" ? (
                    <>
                    Choose from over <b> 2 </b> million hotels and <b> 540+ </b> airlines
                    </>
                  ):LAN === "AR" ? (
                    <>
                    اختر من بين أكثر من <b> 2 </b> مليون فندق وأكثر من <b> 540 </b> شركة طيران
                    </>
                  ):LAN === "KU" ? (
                    <>
                    لە نێوان زیاتر لە <b> ٢ </b> ملیۆن هۆتێل و زیاتر لە <b> ٥٤٠ </b> كۆمپانیای گەشتیاری هێلی ئاسمانی هەڵبژاردنی خۆت بكە
                    </>
                  ): (
                    Dictionary.ChooseFromOver2millionHotelsAnd540Airlines
                  )}                    
                  </div>
                </div>
               ) 
               : LayoutConfig.themeClassName === 'style-3' ?
                null 
               :
                <div className={"page-container hidden-xsmall hidden-small"}>
                  <h2
                    className={"home-top-slogan"}
                    style={{
                      color: LayoutConfig.homeSlogancolor,
                    }}
                  >
                    {LayoutConfig.sloganText && LayoutConfig.sloganText.largeText
                      ? LayoutConfig.sloganText.largeText
                      : Dictionary.letsBookYourNextTrip}

                    {LayoutConfig.sloganText &&
                    LayoutConfig.sloganText.largeText ? (
                      LayoutConfig.sloganText.smallText ? (
                        <span className="subslogan">
                          {LayoutConfig.sloganText.smallText}
                        </span>
                      ) : null
                    ) : (
                      <span className="subslogan">
                        {props.searchType==="flight" ? 
                          Dictionary.chooseFromOver450AirlinesWorldwide
                        :props.searchType==="hotel" ?
                          Dictionary.chooseFromOver1_5MillionHotelsWorldwide
                        :null}
                      </span>
                    )}
                  </h2>
                </div>
              }
              
              {mainSearchBlock}

              { LayoutConfig.themeClassName === 'style-3' && 
                <div className={`${(props.searchType==="flight" || props.searchType==="foreignFlight") ?"style3-bg-airplane" : "" } hidden-xsmall hidden-small`}>

                  <div className="page-container">
                    <h2
                      className={"home-top-slogan"}
                      style={{
                        color: LayoutConfig.homeSlogancolor,
                      }}
                    >
                      {LayoutConfig.sloganText && LayoutConfig.sloganText.largeText
                        ? LayoutConfig.sloganText.largeText
                        : Dictionary.letsBookYourNextTrip}

                      {LayoutConfig.sloganText &&
                      LayoutConfig.sloganText.largeText ? (
                        LayoutConfig.sloganText.smallText ? (
                          <span className="subslogan">
                            {LayoutConfig.sloganText.smallText}
                          </span>
                        ) : null
                      ) : (
                        <span className="subslogan">
                          {props.searchType==="flight" ? 
                            Dictionary.chooseFromOver450AirlinesWorldwide
                          :props.searchType==="hotel" ?
                            Dictionary.chooseFromOver1_5MillionHotelsWorldwide
                          :null}
                        </span>
                      )}
                    </h2>
                  </div>
               
                </div>
              }
              

            </>
          }
        </div>   
    </>

  )}

const mapStateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(Banner);