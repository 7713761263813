import React from "react";
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import HotelNoImage from "../../image/default-hotel-image.svg";
import Rate from "../../components/Common/Rate";
import Price from "../../components/Common/Price";
import { connect } from "react-redux";
import moment from "moment-jalaali";
import LayoutConfig from "../../Config/layoutConfig";
import { Tooltip, Tag } from "antd";
class SummaryHotel extends React.PureComponent {

  render() {

    const safarmarketId = queryString.parse(this.props.location.search).smid;

    const { Dictionary, LAN } = this.props;
    
    const isStyle3 = LayoutConfig.themeClassName === 'style-3';

    return (
      <div className="hotel-summary-card box-border bg-white">
        <h5 className="card-title">
          <div className="hr_padding_half">{Dictionary.bookingSummary}</div>
        </h5>
        <div className="hr_padding_half">
          <div className="hotel-head-info" dir="ltr">
            {this.props.model.accommodation.mainPhoto == null ? (
              <div
                className="hotel-image default-hotel-image"
                style={{ backgroundImage: "url(" + HotelNoImage + ")" }}
              ></div>
            ) : (
              <div
                className="hotel-image  main-hotel-image"
                style={{
                  backgroundImage: `url(${this.props.model.accommodation.mainPhoto})`,
                }}
              ></div>
            )}
            <div className="full-width">
              <h5 className="hotel-title en-font">
                {this.props.model.accommodation.name}
              </h5>
              <div className="hotelRange">
                {this.props.model.accommodation.rating ? (
                  <Rate number={this.props.model.accommodation.rating} />
                ) : null}
              </div>
              <div className="gray font-12">
                {this.props.model.accommodation.address}
              </div>
            </div>
          </div>

          <div className="pair-row">
            <span>{Dictionary.checkIn}</span>
            {safarmarketId ? moment(this.props.model.date.checkIn).format("jYYYY/jMM/jDD") :<Tooltip placement="top" title={Dictionary.propertyLocalTime}>
              <div dir="ltr">
                <span className="semi-bold">
                  {this.props.model.date.checkIn.split("T")[0]}
                </span>
                <span className="itours-icon icon-small info-icon" />
              </div>
            </Tooltip>}
          </div>

          <div className="pair-row">
            <span>{Dictionary.checkOut}</span>
            {safarmarketId ? moment(this.props.model.date.checkOut).format("jYYYY/jMM/jDD") :<Tooltip placement="top" title={Dictionary.propertyLocalTime}>
              <div dir="ltr">
                <span className="semi-bold">
                  {this.props.model.date.checkOut.split("T")[0]}
                </span>
                <span className="itours-icon icon-small info-icon" />
              </div>
            </Tooltip>}
          </div>

          <div className="pair-row">
            <span>{Dictionary.lengthOfStay}</span>
            <span className="semi-bold">
              {this.props.night} {Dictionary.nights}{" "}
            </span>
          </div>

          {/* <div className="dates font-14 margin-bottom-light">
            <div className="inline-block-top itours-icon calendar-dark margin-end-light icon-small" />
            <div className="inline-block-top">
              {this.props.model.date.checkIn.split("T")[0]}
              {LAN === "IR" && (
                <div className="font-12">
                  (
                  {moment(this.props.model.date.checkIn.split("T")[0]).format(
                    "jYYYY-jMM-jDD"
                  )}
                  )
                </div>
              )}
            </div>
            <div className="inline-block-top itours-icon right-arrow-dark margin-end-light icon-small rotate-rtl" />
            <div className="inline-block-top margin-end-light">
              {this.props.model.date.checkOut.split("T")[0]}
              {LAN === "IR" && (
                <div className="font-12">
                  (
                  {moment(this.props.model.date.checkOut.split("T")[0]).format(
                    "jYYYY-jMM-jDD"
                  )}
                  )
                </div>
              )}
            </div>
            <div className="inline-block-top">
              {" "}
              {this.props.night} {Dictionary.nights}{" "}
            </div>
          </div> */}

          {this.props.model.accommodation.rooms.map((item, index) => {
            return (
              <div
                className="clearfix room-item font-14 margin-bottom-light border-top"
                key={index}
              >
                <div className="margin-bottom-light">
                  <div>
                    <span className="itours-icon hotel-dark margin-right-light icon-small" />
                    <span className="semi-bold"> {item.name} </span>
                  </div>
                  <ul>
                    <li>{item.board.name}</li>
                  </ul>

                  <div className="semi-bold">
                    (
                    <span className="inline-block">
                      {item.adults}
                      {item.adults > 1 ? Dictionary.adults : Dictionary.adult}
                    </span>
                    {item.children > 0 ? (
                      <span className="inline-block">
                        <span className="inline-block margin-end-light">
                          {Dictionary.comma}
                        </span>
                        {item.children}{" "}
                        {item.children > 1
                          ? Dictionary.children
                          : Dictionary.child}{" "}
                      </span>
                    ) : null}
                    )
                  </div>
                </div>
                {/*<div className="green"><span className="semi-bold"><Price currency={this.props.currencyCode}>{item.salePrice}</Price></span></div>*/}
              </div>
            );
          })}

          {/*cancelation policy*/}
          {this.props.cancellationPolicy ? (
            <div className="border-top">
              <div>
                <div className="margin-bottom-light margin-top-light">
                  {this.props.cancellationPolicy.status === "Refundable" ? (
                    <Tag color={"green"}>{Dictionary.refundable}</Tag>
                  ) : this.props.cancellationPolicy.status ==="NonRefundable" ? (
                    <Tag color={"red"}>{Dictionary.nonRefundable}</Tag>
                  ) : (
                    <Tag>{this.props.cancellationPolicy.status}</Tag>
                  )}
                </div>

                {this.props.cancellationPolicy.fees.length > 0 ? (
                  <>
                  {LAN === "IR" && <div className="margin-bottom">
                      امکان کنسل کردن فقط تا تاریخ های زیر میسر است و در
                      صورت درخواست کنسلی، 
                      <b> ده درصد مبلغ (حداقل  2,000,000 ریال) </b>
                      بابت کارمزد خدمات از مبلغ کل رزرو کسر میگردد.
                  </div>}
                  <ul className="no-margin ">
                    {this.props.cancellationPolicy.fees.map((rule, index) => {
                      if (rule.amount > 0) {
                        return (
                          <li key={index}>
                            <span className="inline-block margin-end-light">
                              <Price currency={this.props.currencyCode}>
                                {rule.amount}
                              </Price>
                            </span>
                            <span className="inline-block margin-end-light">
                              {Dictionary.afterDate}
                            </span>
                            <span className="inline-block  margin-end-light">
                              <Tooltip
                                title={
                                  <span dir="ltr" className="en-font">
                                    {moment(rule.fromDate).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </span>
                                }
                              >
                                <div className="inline-block">
                                  {moment(rule.fromDate).format("D/M/YYYY")}
                                </div>
                              </Tooltip>
                            </span>
                            {LAN === "IR" && (
                              <span className="inline-block">
                                ({moment(rule.fromDate).format("jYYYY/jM/jD")})
                              </span>
                            )}
                          </li>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </ul>
                  </>
                ) : (
                  <p>{Dictionary.ThereIsNoCancellationRule}</p>
                )}
              </div>
            </div>
          ) : null}

          <div className="card-footer">
            <div className="clearfix">
              <span className="pull-start font-14">
                {Dictionary.totalPrice}
              </span>
              <b className="pull-end semi-bold" dir="ltr">
                <Price currency={this.props.currencyCode}>
                  {this.props.model.salePrice}
                </Price>
              </b>
            </div>
            {this.props.showButton && (
              <div className="confirmButton">
                <button className={`button ${isStyle3?"yellow-btn pale":"red-btn"} full-width`} type="submit">
                  {Dictionary.continueToPayment} {this.props.submitLoading? <span className="loading-inline-circle margin-start-light" /> : null}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default withRouter(connect(mapStateToProps)(SummaryHotel));
